<script setup lang="ts">
const i18nHead = useLocaleHead({ addSeoAttributes: true })

useHead({
  title: () => `${$t('bMO5PvbA0szb_-nqJFnwh')} - ${$t('ZtzqRdD1HHSKd7-9mLEPS')}`,
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || []),
    { property: 'og:image', content: '/favicon.svg' },
    { name: 'description', content: () => `${$t('LzrF6CsFgGZCBYh0_FwRy')}，${$t('va2l6qnwD615aEMKBU2JQ')}` },
  ],
})
</script>

<template>
  <article class="feq-root">
    <div class="feq-content page-content-width">
      <h1>{{ $t('bMO5PvbA0szb_-nqJFnwh') }}</h1>
      <p>{{ $t('LzrF6CsFgGZCBYh0_FwRy') }}</p>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import './style/help-content-page';
</style>
